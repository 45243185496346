import { ActionContext } from 'vuex';
import { api } from '@/api';
import timeout from '@/utils/timeout';
import { RootState } from '../../root-state';
import { User, UserStateInterface } from './types';

interface LoginParams {
  username: string;
  password: string;
}

type Context = ActionContext<UserStateInterface, RootState>;

/**
 * (current) User state management
 */
export default {
  namespaced: true,
  state: <UserStateInterface> {
    user: null,
    token: '',
    isLoggedIn: false,
  },
  mutations: {
    /** Private. Set user data in store */
    setUser(state: UserStateInterface, user: User) {
      state.user = user;
    },
    /** Private. Set token in store */
    setToken(state: UserStateInterface, token: string) {
      state.token = token;
    },
    /** Private. Set isLoggedIn in store */
    setLoggedIn(state: UserStateInterface, loggedIn: boolean) {
      state.isLoggedIn = loggedIn;
    },
  },
  getters: {
    /** Check if user is logged in */
    isLoggedIn: (state: UserStateInterface) : boolean => state.isLoggedIn,
  },
  actions: {
    /** Try to login user using provided username and password. */
    async login(
      context: Context,
      { username, password }: LoginParams,
    ) {
      if (process.env.VUE_APP_NO_SERVER === '1') {
        console.log('Skip server login');
        console.log('Simulate waiting on server');
        await timeout(900);
        context.commit('setLoggedIn', true);
        context.commit('setUser', {
          username,
        });
        return;
      }

      try {
        const response = await api.login(username, password);
        const token = response.data.access_token;
        if (token) {
          localStorage.setItem('token', token);
          context.commit('setToken', token);
          // Server should respond with username in getProfile, but a bug in the server
          // causes it to respond with null. (see issue #8 of API server)
          context.commit('setUser', {
            username,
          });
          await context.dispatch('getProfile');
        } else {
          // Log.log('No token');
          context.dispatch('logout');
        }
      } catch (error) {
        // Log.log(error);
        context.dispatch('logout');
        throw error;
      }
    },
    /** Logout user */
    async logout(context: Context) {
      // Log.log('logout');
      await context.dispatch('removeLogin');
      context.commit('setUser', null);
    },
    /** private helper action that remove the login data */
    async removeLogin(context: Context) {
      // Log.log('removeLogin', context);
      context.commit('setToken', '');
      context.commit('setLoggedIn', false);
      localStorage.removeItem('token');
      localStorage.removeItem('selectedSurveyId');
      localStorage.removeItem('filter');
      localStorage.removeItem('querySettings');
    },
    /** Fetches user data and update user state. */
    async getProfile(context: Context) {
      /* eslint no-useless-catch: ["off"] */
      try {
        const response = await api.getMe(context.state.token);
        // Log.log(response);
        if (response.data) {
          // Server should respond with username, but a bug in the server
          // causes it to respond with null. (see issue #8 of API server)
          if (response.data.username === null && context.state.user !== null) {
            response.data.username = context.state.user.username;
          }
          context.commit('setUser', response.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async checkLoggedIn(context: Context) {
      // Log.log('checkLoggedIn');
      if (!context.state.isLoggedIn) {
        let { token } = context.state;
        if (!token) {
          const localToken = localStorage.getItem('token');
          if (localToken) {
            context.commit('setToken', localToken);
            token = localToken;
          }
        }
        if (token) {
          try {
            const response = await api.getMe(token);
            context.commit('setLoggedIn', true);
            // Server should respond with username, but a bug in the server
            // causes it to respond with null. (see issue #8 of API server)
            if (response.data.username === null && context.state.user !== null) {
              response.data.username = context.state.user.username;
            }
            context.commit('setUser', response.data);
          } catch (error) {
            await context.dispatch('removeLogin');
          }
        } else {
          await context.dispatch('removeLogin');
        }
      }
    },
  },
};
