/**
 * App flavor for data collection app.
 */
export enum AppFlavor {
  travelvu = 'travelvu',
  activeTravel = 'activetravel',
}

/**
  * Corresponds to Survey model in Yii-server
  */
export interface Survey {
  readonly id : number;
  readonly appFlavor : AppFlavor;
  readonly label : string;
  readonly description: string;
  readonly startDate : string|null; // YYYY-MM-DD date
  readonly endDate : string|null; // YYYY-MM-DD date
  readonly hasMapData : boolean; // true => map data is available to this survey.
  participateCountAllTime : number|undefined;
  participateCountAllTimeMinOneCorrDay : number|undefined;
  correctedDayInSurveyCount : number|undefined;
}

/* Declares the state types */
export interface SurveyStateInterface {
  surveyList: Array<Survey>; // list of data sets available to currently logged in user
  selectedSurvey : number; // index to selected data set in surveyList or -1 for no selection
}
