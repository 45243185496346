/**
 * Contains a list of surveys that the user has access to and
 * selection of one of the surveys to use for analysis.
 */

// import store from '.';
import { ActionContext } from 'vuex';
import { api } from '@/api';
import { flavor, flavorData } from '@/flavor';
import { Flavor } from '@/flavor/types';
import { RootState } from '../../root-state';
import { AppFlavor, Survey, SurveyStateInterface } from './types';

interface LoadDetailsParams {
  surveyId: number;
}

export default {
  namespaced: true,
  state: <SurveyStateInterface> {
    surveyList: [],
    selectedSurvey: -1,
  },
  mutations: {
    clear(state:SurveyStateInterface) {
      state.surveyList = [];
      state.selectedSurvey = -1;
    },
    // Pass id: -1 to unset
    selectSurvey(state:SurveyStateInterface, id:number) {
      const index = state.surveyList.findIndex((s) => s.id === id);
      state.selectedSurvey = index;
    },
    // Private. Use load action
    setSurveyList(state:SurveyStateInterface, surveyList : Array<Survey>) {
      // TODO: perserve selectedSurvey
      state.surveyList = surveyList;
      state.selectedSurvey = -1;
    },
  },
  actions: {
    load(context:ActionContext<SurveyStateInterface, RootState>) {
      return new Promise<void>(async (resolve, reject) => {
        if (process.env.VUE_APP_NO_SERVER === '1') {
          if (flavor === Flavor.travelViewer) {
            context.commit('setSurveyList', [
              {
                id: 1,
                appFlavor: AppFlavor.travelvu,
                label: 'RVU Sundsvall',
                description: 'Undersökning bland dagbefolkningen i Sundsvalls tätort',
                startDate: '2019-05-01',
                endDate: '2019-05-15',
                participateCountAllTime: 578,
                correctedDayInSurveyCount: 1481,
              },
              {
                id: 2,
                appFlavor: AppFlavor.travelvu,
                label: 'RVU Östersund',
                description: 'Undersökning bland invånare i Östersunds kommun',
                startDate: '2019-05-08',
                endDate: '2019-05-22',
                participateCountAllTime: 323,
                correctedDayInSurveyCount: 702,
              },
              {
                id: 13,
                appFlavor: AppFlavor.travelvu,
                label: 'Donate Data',
                description: 'Donate your data to TravelVu',
                startDate: null,
                endDate: null,
                participateCountAllTime: 2050,
                correctedDayInSurveyCount: 1481,
              },
            ]);
          } else {
            context.commit('setSurveyList', [
              {
                id: 3,
                appFlavor: AppFlavor.activeTravel,
                label: 'Bolaget',
                description: 'Kampanj för Bolaget',
                startDate: '2019-05-01',
                endDate: '2019-05-15',
                participateCountAllTime: 73,
                correctedDayInSurveyCount: 148,
              },
              {
                id: 97,
                appFlavor: AppFlavor.activeTravel,
                label: 'Test ActiveTravel',
                description: 'Use this survey to test ActiveTravel',
                startDate: null,
                endDate: null,
                participateCountAllTime: 250,
                correctedDayInSurveyCount: 181,
              },
            ]);
          }
        } else {
          try {
            const response = await api.getSurveyList(context.rootState.user.token);
            let surveys = response.data;

            // Only show ActiveTravel surveys in ActiveViewer, but show surveys from
            // both apps in TravelViewer.
            surveys = surveys.filter(
              (s: Survey) => flavor === Flavor.travelViewer || s.appFlavor === flavorData.appFlavor,
            );

            surveys.sort((a: Survey, b : Survey) => {
              if (a.id === b.id) return 0;
              // 1. by start date
              if (a.startDate !== null && b.startDate !== null) {
                if (a.startDate < b.startDate) return 1;
                if (a.startDate > b.startDate) return -1;
              }
              if ((a.startDate === null) !== (b.startDate === null)) {
                if (a.startDate === null) return -1;
                if (b.startDate === null) return 1;
              }
              // 2. by name
              if (a.label < b.label) return -1;
              if (a.label > b.label) return 1;
              return 0;
            });
            context.commit('setSurveyList', surveys);
          } catch (error) {
            const e: any = error;
            if (e.response !== undefined && e.response.status === 401) {
              console.log('401 in response => logout');
              context.dispatch('user/logout', {}, { root: true });
            }
            reject();
          }
        }
        resolve();
      });
    },
    /**
     * loads details for one surevyId
     *
     * Store is updated with details and details is also returned.
     */
    loadDetails(
      context:ActionContext<SurveyStateInterface, RootState>,
      payload : LoadDetailsParams,
    ) {
      return new Promise<Survey>(async (resolve, reject) => {
        try {
          const response = await api.getSurveyDetails(
            payload.surveyId, context.rootState.user.token,
          );
          const survey = context.state.surveyList.find((s : Survey) => s.id === payload.surveyId);
          if (survey !== undefined) {
            survey.correctedDayInSurveyCount = response.data.correctedDayInSurveyCount;
            survey.participateCountAllTime = response.data.participateCountAllTime;
          }
          resolve(<Survey> response.data);
        } catch (e) {
          reject();
        }
      });
    },
    clear(context:ActionContext<SurveyStateInterface, RootState>) {
    },
  },
  getters: {
    getAll(state:SurveyStateInterface) : Array<Survey> {
      return state.surveyList;
    },
    getSelected(state:SurveyStateInterface) : Survey|null {
      return state.surveyList[state.selectedSurvey] !== undefined
        ? state.surveyList[state.selectedSurvey] : null;
    },
  },
};
