import { Flavor, FlavorData } from './types';
import { getFlavorData, parseFlavorStr } from './utils';

// Get flavor from VUE_APP_FLAVOR,
// parse it and throw exception if invalid.
const vueAppFlavor = process.env.VUE_APP_FLAVOR;
const flavorOrNull = parseFlavorStr(vueAppFlavor ?? '');

if (flavorOrNull === null) {
  throw Error('Invalid VUE_APP_FLAVOR env parameter. Only "travelviewer" or "activeviewer" is accepted');
}
const flavorDataOrNull = getFlavorData(flavorOrNull);
if (flavorDataOrNull === null) {
  throw Error('Invalid flavor');
}

// Then expose non-null Flavor and FlavorData

/** Flavor of this build of travelviewer-ui */
export const flavor:Flavor = flavorOrNull!;

/** Describes the runtime viewer flavor. */
export const flavorData:FlavorData = flavorDataOrNull!;
