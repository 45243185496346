import axios, { AxiosResponse, CancelToken, AxiosRequestConfig } from 'axios';
import { Query, QueryResult, ResultQueryPromise } from '@/store/modules/query/types';
import { apiUrl } from '@/env';
import { authHeaders } from './auth_utils';

export default {
  async resultQuery(
    query: Query,
    token: string,
    cancelToken: CancelToken|undefined = undefined,
  ) : ResultQueryPromise {
    const config : AxiosRequestConfig = authHeaders(token);
    config.cancelToken = cancelToken;
    return axios.post(`${apiUrl}/v1/result-query`, query, config);
  },
};
