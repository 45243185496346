import axios from 'axios';
import { Mode, AggregatedModeConfig } from '@/store/modules/mode-activity/types';
import { apiUrl } from '@/env';
import { authHeaders } from './auth_utils';

export default {
  async getModes(surveyId : number|null, token : string) {
    const config = authHeaders(token);
    if (surveyId !== null) {
      config.params = new URLSearchParams({
        surveyId: surveyId.toString(),
      });
    }
    return axios.get<Array<Mode>>(`${apiUrl}/v1/modes/list`, config);
  },
//  async setAggregatedModes(surveyId : number, aggregatedModes : AggregatedModeConfig) {
//    return axios.post(`${apiUrl}/v1/aggregated-mode`, {
//      surveyId,
//      aggregatedModes,
//    });
//  },
//  async getAggregatedModes(surveyId : number) {
//    const params = new URLSearchParams({
//      surveyId: surveyId.toString(),
//    });
//    return axios.get<AggregatedModeConfig>(`${apiUrl}/v1/aggregated-mode`, { params });
//  },
};
