import { ActionContext } from 'vuex';
import { stringGetDayAfter } from '@/utils/dateHelpers';
import Log from '@/utils/log';
import { RootState } from '../../root-state';
import { FilterStateInterface } from './types';
import { AggregatedMode, AggregatedActivity } from '../mode-activity/types';
import { SampleVariable, SampleClass } from '../sample/types';
import { Survey } from '../survey/types';

export interface EnableAllParams {
  aggregatedModes: Array<AggregatedMode>;
  aggregatedActivities: Array<AggregatedActivity>;
  sampleVariables : Array<SampleVariable>;
  surveyStartDate: string;
  surveyEndDate: string;
}

export interface SetByIdParams {
  id: number;
  enabled: boolean;
}

export interface SetSampleClassParams {
  variableId: number;
  classId: number;
  enabled: boolean;
}

type Context = ActionContext<FilterStateInterface, RootState>;

const enabledClassesStateGetter = (state:FilterStateInterface) => {
  let s = '';
  Object.keys(state.sampleClassIds).forEach((k : string) => {
    const svId = parseInt(k, 10);
    s += `v${svId}:`;
    state.sampleClassIds[svId].forEach((cId : number) => {
      s += `${cId},`;
    });
  });
  return s;
};

function persist(state: FilterStateInterface) {
  localStorage.setItem('filter', JSON.stringify(state));
}

/* eslint no-underscore-dangle: ["off", {}}] */
/* eslint prefer-template: ["off", {}}] */

/**
 * (current) User state management
 */
export default {
  namespaced: true,
  state: <FilterStateInterface> {
    aggregatedModes: [],
    aggregatedActivities: [],
    sampleClassIds: {},
    startDate: '1990-01-01', // YYYY-MM-DD start date
    endDate: '1990-01-01', // YYYY-MM-DD end date
    startTime: 0, // seconds since 00:00. [0, 24*3600]
    endTime: 0, // seconds since 00:00. [0, 24*3600]
    dayOfWeek: [false, false, false, false, false, false, false],
    minDistanceM: null,
    maxDistanceM: null,
    minDurationS: null,
    maxDurationS: null,
    isCommuteToWork: null,
    _enabledClassesStr: '',
  },
  mutations: {
    /**
     * Unsafe setter (no typechecking or data validation)
     * @param filter Object with same names as state as keys.
     */
    setFilter(state: FilterStateInterface, filter: any) {
      Object.keys(state).forEach((key : string) => {
        if (filter[key] !== undefined
          && (<any>state)[key] !== undefined
          && !key.startsWith('_')
        ) {
          const oldValue = (<any>state)[key];
          (<any>state)[key] = (<any>filter)[key];

          if (key === 'aggregatedModes') {
            state.aggregatedModes.sort();
          } else if (key === 'aggregatedActivities') {
            state.aggregatedActivities.sort();
          } else if (key === 'sampleClassIds') {
            Object.keys(state.sampleClassIds).forEach((k : string) => {
              const svId = parseInt(k, 10);
              state.sampleClassIds[svId].sort();
            });
            // For variables that exist only in the old filter state, but
            // not in the new sampleClassIds assignment, retain the old
            // filter state. Purpose: so restore can default to enabled
            Object.keys(oldValue).forEach((k : string) => {
              const oldSvId = parseInt(k, 10);
              if (state.sampleClassIds[oldSvId] === undefined) {
                Log.log(`setFilter restore old sv class state for ${oldSvId}`);
                state.sampleClassIds[oldSvId] = oldValue[oldSvId];
              }
            });
            state._enabledClassesStr = enabledClassesStateGetter(state);
          }
        }
      });
      persist(state);
    },
    /**
     * Enable/disable one aggregated mode specified by id.
     * @param state
     * @param params
     */
    setAggregatedMode(state: FilterStateInterface, params: SetByIdParams) {
      if (params.enabled) {
        if (state.aggregatedModes.find((mId) => mId === params.id) !== undefined) {
          return;
        }
        state.aggregatedModes.push(params.id);
        // Sort is important to not create multiple filter/query states with different mode order
        state.aggregatedModes.sort();
      } else {
        const index = state.aggregatedModes.findIndex((mId) => mId === params.id);
        if (index !== -1) {
          state.aggregatedModes.splice(index, 1);
        }
      }
      persist(state);
    },
    /**
     * Enable/disable one aggregated activity specified by id.
     * @param state
     * @param params
     */
    setAggregatedActivity(state: FilterStateInterface, params: SetByIdParams) {
      if (params.enabled) {
        if (state.aggregatedActivities.find((aId) => aId === params.id) !== undefined) {
          return;
        }
        state.aggregatedActivities.push(params.id);
        state.aggregatedActivities.sort();
      } else {
        const index = state.aggregatedActivities.findIndex((aId) => aId === params.id);
        if (index !== -1) {
          state.aggregatedActivities.splice(index, 1);
        }
      }
      persist(state);
    },
    /**
     * Enable/disable one sample class specified by class and variable id
     * @param state
     * @param params
     */
    setAnswerOptionKeyId(state: FilterStateInterface, params: SetSampleClassParams) {
      if (params.enabled) {
        if (state.sampleClassIds[params.variableId] === undefined) {
          state.sampleClassIds[params.variableId] = {};
        }

        if (state.sampleClassIds[params.variableId].find(
          (classId : number) => classId === params.classId,
        ) === undefined) {
          // add class if it is missing
          state.sampleClassIds[params.variableId].push(params.classId);
          state.sampleClassIds[params.variableId].sort();
        }
      } else {
        if (state.sampleClassIds[params.variableId] === undefined) {
          return; // variable is complely missing - should maybe not happen
        }
        const index = state.sampleClassIds[params.variableId].findIndex(
          (classId : number) => classId === params.classId,
        );
        if (index !== -1) {
          state.sampleClassIds[params.variableId].splice(index, 1);
          state.sampleClassIds[params.variableId].sort();
        }
        // Don't remove the variable if all classes have been removed.
        // An omitted variable causes statistics engine to not filter
        // on the variable at all.
      }

      // Update _enabledClassesStr as filter state in object doesn't trigger
      // Vue change detection
      state._enabledClassesStr = enabledClassesStateGetter(state);

      persist(state);
    },
    /**
     * Enable all filters
     * @param state
     * @param params Lists of all modes, activities etc.
     */
    enableAll(state: FilterStateInterface, params : EnableAllParams) {
      state.aggregatedModes = params.aggregatedModes.map((m) => m.id).sort();
      state.aggregatedActivities = params.aggregatedActivities.map((a) => a.id).sort();

      state.sampleClassIds = {};
      params.sampleVariables.forEach((sv : SampleVariable) => {
        if (!sv.isShare || sv.id === null) {
          return;
        }

        const svClasses : Array<number|null> = [];
        sv.classes.forEach((c : SampleClass) => {
          svClasses.push(c.id);
        });
        svClasses.push(null);
        svClasses.sort();
        state.sampleClassIds[sv.id] = svClasses;
      });
      state._enabledClassesStr = enabledClassesStateGetter(state);

      state.startDate = params.surveyStartDate;
      state.endDate = params.surveyEndDate;
      state.startTime = 0;
      state.endTime = 24 * 3600 - 1;
      state.dayOfWeek = [true, true, true, true, true, true, true];
      state.minDistanceM = null;
      state.maxDistanceM = null;
      state.minDurationS = null;
      state.maxDurationS = null;
      state.isCommuteToWork = null;

      persist(state);
    },
    /**
     * Disable all filters that depends on external data (modes etc.)
     *  - all array filters is set to []
     *  - sets time filter to [0, 23:59:59]
     *  - sets date filter to no date range
     *  - sets min/max distance/duration to null
     * @param state
     * @param params
     */
    clear(state: FilterStateInterface) {
      state.aggregatedModes = [];
      state.aggregatedActivities = [];
      state.sampleClassIds = [];
      state.startDate = null;
      state.endDate = null;
      state.startTime = 0;
      state.endTime = 24 * 3600 - 1;
      state.dayOfWeek = [true, true, true, true, true, true, true];
      state.minDistanceM = null;
      state.maxDistanceM = null;
      state.minDurationS = null;
      state.maxDurationS = null;
      state.isCommuteToWork = null;
    },
  },
  actions: {
    restore(context:ActionContext<FilterStateInterface, RootState>) {
      const filterString = localStorage.getItem('filter');
      const selectedSurvey : Survey|null = context.rootGetters['survey/getSelected'];
      context.commit('enableAll', {
        aggregatedModes: context.rootGetters['modeActivity/getAggregatedModesExcludingExercice'],
        aggregatedActivities: context.rootState.modeActivity.aggregatedActivities,
        sampleVariables: context.rootState.sample.variables,
        surveyStartDate: selectedSurvey?.startDate,
        surveyEndDate: selectedSurvey?.endDate,
      } as EnableAllParams); // default for filters not in store
      if (filterString !== null) {
        const filter = JSON.parse(filterString);
        if (filter !== null) {
          context.commit('setFilter', filter);
        }
      }
    },
  },
  getters: {
    // allow having the reducer on multiple lines for
    // better readability what they do
    /* eslint implicit-arrow-linebreak: ["off", {}}] */
    /* eslint function-paren-newline: ["off", {}}] */

    /**
     * Provides a unique string that represent the current filter
     * state. It can be used to react to changes to the filter
     * state but is not ment to be used for data transfer and
     * being decoded later on.
     */
    filterState: (state:FilterStateInterface) => {
      let s = '';

      s += 'am:';
      s += state.aggregatedModes.map((am : number) => am.toString())
        .reduce((reducer, val, index) =>
          `${reducer}${val},`, '',
        );
      s += 'aa:';
      s += state.aggregatedActivities.map((aa : number) => aa.toString())
        .reduce((reducer, val, index) =>
          `${reducer}${val},`, '',
        );

      s += 'ao:' + state._enabledClassesStr;

      s += `fd:${state.startDate}`;
      s += `ed:${state.endDate}`;
      s += `ft:${state.startTime}`;
      s += `et:${state.endTime}`;
      s += 'dow:';
      s += state.dayOfWeek.map((dow : boolean) => dow.toString())
        .reduce((reducer, val, index) =>
          `${reducer}${val},`, '',
        );

      s += `di:${state.minDistanceM}-${state.maxDistanceM}`;
      s += `du:${state.minDurationS}-${state.maxDurationS}`;

      return s;
    },
    /** Internal getter to compute _enabledAOsStr */
    _enabledClassesStateStr: enabledClassesStateGetter,
    /**
     * Check if given answer option is enabled in the filter
     * @param variableId SampleVariable id
     * @param classId SampleClass id or null for the psuedo answer 'missing answer'.
     */
    isSampleClassEnabled: (state:FilterStateInterface) => (
      variableId : number, classId : number|null,
    ) => {
      if (state.sampleClassIds[variableId] === undefined) return false;
      return state.sampleClassIds[variableId].indexOf(classId) !== -1;
    },
  },
};
