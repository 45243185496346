import Vue from 'vue';
import Vuex from 'vuex';
import UserModule from './modules/user/index';
import SurveyModule from './modules/survey/index';
import SampleModule from './modules/sample/index';
import ModeActivityModule from './modules/mode-activity/index';
import Questionnaire from './modules/questionnaire/index';
import HomeArea from './modules/home-area/index';
import FilterModule from './modules/filter/index';
import ResultBoardModule from './modules/result-board/index';
import QueryModule from './modules/query/index';
import QuerySettingsModule from './modules/query-settings/index';
import { RootState } from './root-state';
import { QuestionType } from './modules/questionnaire/types';
import { selectSurvey, restoreSelectedSurvey } from './actions';

Vue.use(Vuex);

/*
 * Principle:
 *   No module mutation or action may write to
 *   another module.
 *
 * Any orchestrated mutation or action that affect
 * multiple modules are placed at root level.
 */

export default new Vuex.Store<RootState>({
  mutations: {
  },
  actions: {
    selectSurvey,
    restoreSelectedSurvey,
  },
  modules: {
    user: UserModule,
    survey: SurveyModule,
    sample: SampleModule,
    modeActivity: ModeActivityModule,
    filter: FilterModule,
    resultBoard: ResultBoardModule,
    query: QueryModule,
    querySettings: QuerySettingsModule,
    questionnaire: Questionnaire,
    homeArea: HomeArea,
  },
});
