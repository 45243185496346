import { flavor } from '@/flavor';
import { Flavor } from '@/flavor/types';
import Log from '@/utils/log';
import { ActionContext } from 'vuex';
import { EnableAllParams } from './modules/filter';
import { ResultBoardLoadParams } from './modules/result-board';
import { Survey } from './modules/survey/types';
import { RootState } from './root-state';

export interface SelectSurveyParams {
  surveyId : number;
  startDate : string;
  endDate : string;
  restore : boolean; // true to restore filter/querySettings
}

/**
 * Set given surveyId as selected survey and load filters etc.
 * for that survey.
 * @param context
 * @param payload
 * @returns
 */
export async function selectSurvey(
  context : ActionContext<RootState, RootState>,
  payload : SelectSurveyParams,
) {
  return new Promise<void>((resolve, reject) => {
    context.commit('survey/selectSurvey', payload.surveyId);
    try {
      Log.log(`select survey - restore: ${payload.restore}`);
      Log.log('clear');
      if (!payload.restore) {
        context.commit('filter/clear');
        context.commit('querySettings/clear');
      }
      context.commit('sample/clear');
      context.commit('modeActivity/clear');
      context.commit('questionnaire/clear');
      context.commit('resultBoard/clear');
      context.commit('questionnaire/clear');
      Log.log('load');
      const waitOn :Array<Promise<any>> = [];
      if (flavor === Flavor.activeViewer) {
        waitOn.push(context.dispatch('questionnaire/load'));
      }
      Promise.all([
        context.dispatch('modeActivity/load'),
        context.dispatch('sample/load'),
      ]).then(async () => {
        if (flavor === Flavor.activeViewer) {
          context.commit('modeActivity/mainAggregateModes');
          context.commit('modeActivity/mainAggregateActivities');
          context.commit('querySettings/set', {
            includeJoinDateInSurvey: false,
            nRequiresTrips: true,
          });
        }
        Log.log('enable filters');
        if (!payload.restore) {
          context.commit('filter/enableAll', {
            aggregatedModes: context.getters['modeActivity/getAggregatedModesExcludingExercice'],
            aggregatedActivities: context.state.modeActivity.aggregatedActivities,
            sampleVariables: context.state.sample.variables,
            surveyStartDate: payload.startDate,
            surveyEndDate: payload.endDate,
          } as EnableAllParams);
          if (flavor === Flavor.activeViewer) {
            context.commit('filter/setFilter', {
              isCommuteToWork: true,
            });
          }
        } else {
          await context.dispatch('filter/restore');
          await context.dispatch('querySettings/restore');
        }
        // load result board last.
        Log.log('load result boards');
        waitOn.push(context.dispatch('resultBoard/load', {
          sampleVariables: context.state.sample.variables,
          surveyStartDate: payload.startDate,
          surveyEndDate: payload.endDate,
          aggregatedModes: context.state.modeActivity.aggregatedModes,
        } as ResultBoardLoadParams));
        await Promise.all(waitOn);
        window.localStorage.setItem('selectedSurveyId', `${payload.surveyId}`);
        resolve();
      });
    } catch (e) {
      // Error => unselect
      context.commit('survey/selectSurvey', -1);
      reject();
    }
  });
}

/**
 * Restore selected survey from persisted selection in localStorage
 *
 * Perform survey/load and then root selectSurvey if a surveyId is
 * persisted in selectedSurveyId in local storage.
 * @param context
 * @returns
 */
export async function restoreSelectedSurvey(
  context : ActionContext<RootState, RootState>,
) {
  const surveyIdString = window.localStorage.getItem('selectedSurveyId');
  if (surveyIdString === null) {
    Log.log('restoreSelectedSurvey no stored selection => abort');
    return;
  }
  const surveyId = parseInt(surveyIdString, 10);

  // If there is a selection already, don't restore.
  if (context.getters['survey/getSelected'] !== null) {
    Log.log('restoreSelectedSurvey has selection => abort');
    return;
  }
  if (context.getters['survey/getAll'].length === 0) {
    await context.dispatch('survey/load');
  }

  const surveyList : Array<Survey> = context.getters['survey/getAll'];
  const survey = surveyList.find((survey) => survey.id === surveyId);
  if (survey === null) {
    Log.log(`selectedSurveyId ${surveyId} in storage is no longer available`);
    return;
  }
  await context.dispatch('selectSurvey', {
    surveyId,
    startDate: survey!.startDate,
    endDate: survey!.endDate,
    restore: true,
  } as SelectSurveyParams);
}
