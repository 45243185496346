import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { flavor } from './flavor';
import { Flavor } from './flavor/types';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  if (typeof jest !== 'undefined') {
    // Testing environment
    return {};
  }

  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      const localeMessages = locales(key);

      // There is no point of maintaining duplicate translations that only differ on appName or viewerName.
      // Until we need to inform about the other flavor, we can str-replace brand names.
      //
      // It would be better if this could be done at build-time, but for now the string count is not
      // very high.
      if (flavor === Flavor.activeViewer) {
        Object.keys(localeMessages).forEach((key: string) => {
          if (localeMessages[key] !== null && localeMessages[key] !== undefined) {
            localeMessages[key] = localeMessages[key].replaceAll('TravelViewer', 'ActiveViewer');
            localeMessages[key] = localeMessages[key].replaceAll('TravelVu', 'ActiveTravel');
          }
        });
      }

      messages[locale] = localeMessages;
    }
  });
  return messages;
}

function getDefaultLanguage(messages : any) : string {
  // Default language => try to detect user language but fallback to english if unknown
  let defaultLang = navigator.language || (navigator as any).userLanguage;
  if (typeof defaultLang === 'string') {
    defaultLang = defaultLang.substring(0, 2); // eg. sv-SE, en-US. => 'sv', 'en'
  }
  // Norway have two languages Nynorsk and Bokmål with codes nn-NO and nb-NO.
  // Change both to 'no' which is what we use in TravelVu for our single Norwegian
  // language.
  if (['nn', 'nb'].indexOf(defaultLang) !== -1) {
    defaultLang = 'no';
  }

  if (messages[defaultLang] === undefined) {
    // Fallback to English if user have a non-supported language on their phone
    defaultLang = 'en';
  }

  return defaultLang;
}

const messages = loadLocaleMessages();

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || getDefaultLanguage(messages) || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});
