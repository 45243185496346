import { ActionContext } from 'vuex';
import { RootState } from '../../root-state';
import { QuerySettingsStateInterface } from './types';

type Context = ActionContext<QuerySettingsStateInterface, RootState>;

/* eslint key-spacing: ["off"] */
function _initialState() : QuerySettingsStateInterface {
  return <QuerySettingsStateInterface> {
    includeJoinDateInSurvey: true,
    limits: {
      minDistanceM: {},
      maxDistanceM: {
        // Limits values from RVU manual, but extended to have all modes
        WALK:    25 * 1000,
        CAR_:  1500 * 1000,
        CARD:  1500 * 1000,
        CARP:  1500 * 1000,
        ECAR:  1500 * 1000,
        ECRD:  1500 * 1000,
        ECRP:  1500 * 1000,
        NCAR:  1500 * 1000,
        NCRD:  1500 * 1000,
        NCRP:  1500 * 1000,
        PCAR:  1500 * 1000,
        BCAR:  1500 * 1000,
        SCAR:  1500 * 1000,
        RCAR:  1500 * 1000,
        BUS_:  1500 * 1000,
        SBUS:  1500 * 1000,
        CBUS:  1500 * 1000,
        LORY:  1500 * 1000,
        VAN_:  1500 * 1000,
        FERY:  1000 * 1000,
        TRAM:   250 * 1000,
        METR:   250 * 1000,
        CTRN:   250 * 1000,
        TRN_:  2000 * 1000,
        CABE:   100 * 1000,
        BIKE:   100 * 1000,
        EBKE:   100 * 1000,
        CBKE:   100 * 1000,
        SBIK:   100 * 1000,
        SCOT:   500 * 1000,
        SCOM:   500 * 1000,
        MOPD:   500 * 1000,
        MC__:  1500 * 1000,
        TAXI:   500 * 1000,
        TAXR:   500 * 1000,
        TUKT:   500 * 1000,
        MBSR:   500 * 1000,
        EXRC:   500 * 1000,
        AIR_: 25000 * 1000,
        OTHR: 10000 * 1000,
        UKNW: 10000 * 1000,
      },
      minDurationS: {},
      maxDurationS: {
        WALK:  5 * 3600,
        CAR_: 24 * 3600,
        CARD: 24 * 3600,
        CARP: 24 * 3600,
        ECAR: 24 * 3600,
        ECRD: 24 * 3600,
        ECRP: 24 * 3600,
        NCAR: 24 * 3600,
        NCRD: 24 * 3600,
        NCRP: 24 * 3600,
        PCAR: 24 * 3600,
        BCAR: 24 * 3600,
        SCAR: 24 * 3600,
        RCAR: 24 * 3600,
        BUS_: 24 * 3600,
        SBUS: 24 * 3600,
        CBUS: 24 * 3600,
        LORY: 24 * 3600,
        VAN_: 24 * 3600,
        FERY: 36 * 3600,
        TRAM:  5 * 3600,
        METR:  5 * 3600,
        CTRN:  5 * 3600,
        TRN_: 36 * 3600,
        CABE:  5 * 3600,
        BIKE: 10 * 3600,
        EBKE: 10 * 3600,
        CBKE: 10 * 3600,
        SBIK: 10 * 3600,
        SCOT: 10 * 3600,
        SCOM: 10 * 3600,
        MOPD: 10 * 3600,
        MC__: 24 * 3600,
        TAXI: 10 * 3600,
        TAXR: 10 * 3600,
        TUKT: 10 * 3600,
        MBSR: 10 * 3600,
        EXRC: 15 * 3600,
        AIR_: 36 * 3600,
        OTHR: 36 * 3600,
        UKNW: 36 * 3600,
      },
    },
    minCellN: 5,
    weightLimits: {
      minClassN: 0, // TODO: should default to 5
      minVariableN: 0, // TODO: should default to 100
      warnVariableN: 100, // TODO: should default to 100
    },
    activityMethod: 'end-activity',
    nRequiresTrips: false,
    vtiReselementMerge: false,
  };
}

function persist(state: QuerySettingsStateInterface) {
  localStorage.setItem('querySettings', JSON.stringify(state));
}

/* eslint no-underscore-dangle: ["off", {}}] */
/* eslint prefer-template: ["off", {}}] */

/**
 * (current) User state management
 */
export default {
  namespaced: true,
  state: _initialState(),
  mutations: {
    /**
     * Unsafe setter (no typechecking or data validation)
     * @param querySettings Object with same names as state as keys.
     */
    set(state: QuerySettingsStateInterface, querySettings: any) {
      Object.keys(state).forEach((key : string) => {
        if (querySettings[key] !== undefined
          && (<any>state)[key] !== undefined
        ) {
          (<any>state)[key] = (<any>querySettings)[key];
        }
      });
      persist(state);
    },
    /**
     * Reset to deafult state
     * @param state
     * @param params
     */
    clear(state: QuerySettingsStateInterface) {
      const initialState = _initialState();
      Object.keys(state).forEach((key : string) => {
        (<any>state)[key] = (<any>initialState)[key];
      });
      persist(state);
    },
  },
  actions: {
    restore(context:ActionContext<QuerySettingsStateInterface, RootState>) {
      const querySettingsString = localStorage.getItem('querySettings');
      if (querySettingsString !== null) {
        const querySettings = JSON.parse(querySettingsString);
        if (querySettings !== null) {
          context.commit('set', querySettings);
        }
      }
    },
  },
  getters: {
    /**
     * Provides a unique string that represent the current query settings
     * state. It can be used to react to changes to the query settings
     * state but is not ment to be used for data transfer and
     * being decoded later on.
     */
    querySettingsState: (state:QuerySettingsStateInterface) => {
      let s = '';

      s += `i:${state.includeJoinDateInSurvey ? '0' : '1'}`;
      s += 'lim:';
      Object.keys(state.limits).forEach((limit : string) => {
        let sm = '';
        const limitObj = (<any>state.limits)[limit];
        Object.keys(limitObj).forEach((mode : string) => {
          if (sm !== '') sm += ',';
          sm += `${mode}:${limitObj[mode]}`;
        });
        s += sm;
      });
      s += `mcn:${state.minCellN}`;
      s += `wl:${state.weightLimits.minClassN},${state.weightLimits.minVariableN},${state.weightLimits.warnVariableN}`;
      s += `am:${state.activityMethod}`;
      s += `nrt:${state.nRequiresTrips ? '0' : '1'}`;
      s += `vrm:${state.vtiReselementMerge ? '0' : '1'}`;

      return s;
    },
  },
};
