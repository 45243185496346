import { ActionContext } from 'vuex';
import { api } from '@/api';
import { RootState } from '../../root-state';
import { HomeArea, HomeAreaStateInterface } from './types';

type Context = ActionContext<HomeAreaStateInterface, RootState>;

/**
 * Home areas
 */
export default {
  namespaced: true,
  state: <HomeAreaStateInterface> {
    homeAreas: [],
    loaded: false,
  },
  mutations: {
    setHomeAreas(state : HomeAreaStateInterface, homeAreas : Array<HomeArea>) {
      state.homeAreas = homeAreas;
      state.loaded = true;
    },
    /**
     * Removes all home areas.
     */
    clear(state : HomeAreaStateInterface, params : void) {
      state.homeAreas = [];
      state.loaded = false;
    },
  },
  actions: {
    load(context:ActionContext<HomeAreaStateInterface, RootState>) {
      return new Promise<void>(async (resolve, reject) => {
        context.commit('clear');

        const survey = context.rootGetters['survey/getSelected'];
        if (survey !== null) {
          try {
            const response = await api.getHomeAreas(survey.id, context.rootState.user.token);
            const s2 = context.rootGetters['survey/getSelected'];
            if (s2 === null || s2.id !== survey.id) {
              reject();
              return;
            }

            // Loaded
            context.commit('setHomeAreas', response.data);
            resolve();
          } catch (e) {
            console.log('Failed to load home areas');
            reject();
          }
        } else {
          console.log('Cannot load home areas when no survey is selected');
          reject();
        }
      });
    },
  },
  getters: {
  },
};
