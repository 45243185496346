/* eslint quote-props: ["off", {}}] */
/* eslint key-spacing: ["off", {}}] */
/* eslint object-property-newline: ["off", {}}] */
/* eslint max-len: ["off", {}}] */

/**
 * The graph needs colors in JS. this palette should have same colors as the
 * palette in _variables.scss
 */
export default <any> {
  '1':    '#3961FF', '1a':     '#071756', '1b':     '#203EB3', '1c':     '#93A9FD', '1d':     '#D3DCFF',
  '2':    '#BB7DDD', '2a':     '#49076E', '2b':     '#7A2DA4', '2c':     '#E3AFFF', '2d':     '#F0D5FF',
  '3':    '#63C8A0', '3a':     '#004027', '3b':     '#229567', '3c':     '#8AEEC6', '3d':     '#C2FFE7',
  '4':    '#F56B92', '4a':     '#850328', '4b':     '#C72E59', '4c':     '#FFA4BE', '4d':     '#FFD7E2',
  '5':    '#F6914E', '5a':     '#A74402', '5b':     '#D96C23', '5c':     '#FFB27F', '5d':     '#FFD7BC',
  'grey': '#C3C8D6', 'grey-a': '#18191E', 'grey-b': '#545B6F', 'grey-c': '#A0A4B0', 'grey-d': '#E9EAEE',

  'black': '#18191E',
  'white': '#ffffff',
};
