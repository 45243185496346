import { AxiosRequestConfig } from 'axios';

export const foo = 'bar';
export function authHeaders(token: string = '') : AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
