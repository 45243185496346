import { AppFlavor } from '@/store/modules/survey/types';

/**
 * flavor of travelviewer-ui instance
 */
export enum Flavor {
  travelViewer = 'travelviewer',
  activeViewer = 'activeviewer',
}

export interface FlavorData {
  id: Flavor;
  name: string;
  appFlavor: AppFlavor;
  supportUrl: string;
  appSupportUrl: string;
}
