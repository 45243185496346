import { PartialQuery, QueryResult } from '../query/types';

export enum ResultVisual {
  pieChart = 'pie',
  lineChart = 'line',
  barChart = 'bar',
  horizontalBarChart = 'horizontalBar',
  table = 'table',
  factText = 'fact',
  odMatrix = 'od-matrix',
  // Non-computational types:
  sectionHeading = 'heading',
}

/** Scales displayed result value */
export enum ResultFormatter {
  sToMinutes = 'sToMinutes',
  sToHours = 'sToHours',
  mToKm = 'mToKm',
  decToPercent = 'decToPercent'
}

/** What unit is the result in */
export enum ResultUnit {
  minutes = 'minutes',
  hours = 'hours',
  km = 'km',
  kg = 'kg',
}

export interface DataColor {
  bgColor: string;
  textColor: string;
}

// Optional filter overrides that a result box can specify. This allows
// a ResultBox to override these filters that otherwise usually is set
// via Filter store
//
// The types should be the same as in FilterStateInterface except for
// an additional undefined type. When an override is non-undefined
// it is applied.
//
// See FilterStateInterface for documentation of these properties.
export interface FilterOverride {
  aggregatedModes: undefined|Array<number>;
  // isCommuteToWork: undefined|boolean|null;
}

export interface ResultBox {
  id: number; // unique id of box across result boards
  type: ResultVisual;
  colspan: number; // number of columns that the ResultBox spans. If it is more than remaining columns on current
                   // row it will cause a row break.
  label: string; // heading/label of result box
  factTextStr: null|string; // name of string in en.json for text
                            // based result with placeholder {value}

  // Describes how to format/transform ie. cells[0][0] to the value to include
  // in a fact text or use as y value in a graph.
  valueFormatter : null|ResultFormatter;
  valueDecimals : number;
  valueUnit: null|ResultUnit;
  // null: use getAxisXColor and getAxisYColor default
  // array: an array of colors that is cycled.
  // map: a map from axis id to DataColor
  customPalette: null|Array<DataColor>|any;

  // May be null for non-computational ResultVisuals like heading.
  partialQuery : null|PartialQuery;
  filterOverride: FilterOverride;

  // todo: size/layout props
}

/**
 * Represent the result board info.
 */
export interface ResultBoardInfo {
  image: string;
  imageLabel: string | null;
  palette: string;
  label: string;
  textCol1: string;
  textCol2: string;
}

/**
 * Represent the result boxes visible on one result board.
 */
export interface ResultBoard {
  id: string;
  labelStr: string; // name of string in en.json
  resultBoxes : Array<ResultBox>;
  info: ResultBoardInfo | null; // Add a headerbox to the ResultBoard
}

export interface ResultBoardStateInterface {
  resultBoards : Array<ResultBoard>;
}
