import i18n from '@/i18n';
import {
  Activity, AggregatedActivity, AggregatedMode, Mode,
} from '@/store/modules/mode-activity/types';
import { copyLabelTranslations } from './I18nLabel';
import palette from './palette';

/**
 * Get mode by id
 * @param id Mode id
 * @return Mode or null if mode was not found
 */
function getMode(id : string, modes:Array<Mode>) : Mode|null {
  const mode = modes.find((m : Mode) => m.id === id);
  if (mode === undefined) return null;
  return mode;
}

/**
 * Get activity by id
 * @param id Activity id
 * @return Activity or null if activity was not found
 */
function getActivity(id : string, activities:Array<Activity>) : Activity|null {
  const activity = activities.find((a : Activity) => a.id === id);
  if (activity === undefined) return null;
  return activity;
}

/**
 * Get labelTranslations object by looking up
 * given string in app locale strings.
 * @param strId
 * @return object that maps from locale to translation
 */
function getTranslations(strId : string) : any {
  const r : any = {};
  i18n.availableLocales.forEach((locale: string) => {
    r[locale] = i18n.t(strId, locale);
  });
  return r;
}

/** Creates a aggregate setup that just 1:1 mirrors available modes */
export function getNoAggregateModes(modes : Array<Mode>) : Array<AggregatedMode> {
  const aggregatedModes = modes
    .filter((m : Mode) => m.inSurvey)
    .map((m : Mode) : AggregatedMode => {
      const mode = {
        id: 0,
        order: m.order,
        labelTranslations: copyLabelTranslations(m.labelTranslations),
        bgColor: m.bgColor,
        textColor: m.textColor,
        borderColor: m.borderColor,
        svgIconBgColor: m.svgIconBgColor,
        svgIconBlack: m.svgIconBlack,
        modeList: [m.id],
      };
      if (m.id === 'OTHR') {
        mode.modeList.push(
          ...modes.filter((m2 : Mode) => !m2.inSurvey).map((m2 : Mode) => m2.id),
        );
      }
      return mode;
    });

  // assign some ids
  for (let i = 0; i < aggregatedModes.length; i += 1) {
    aggregatedModes[i].id = i;
  }

  // sort by order
  aggregatedModes.sort((a, b) => a.order - b.order);

  return aggregatedModes;
}

/** Creates a aggregate setup that just 1:1 mirrors available activities */
export function getNoAggregateActivities(activities : Array<Activity>) : Array<AggregatedActivity> {
  const aggregatedActivities = activities
    .filter((a : Activity) => a.inSurvey)
    .map((a : Activity) : AggregatedActivity => {
      const activity = {
        id: 0,
        order: a.order,
        labelTranslations: copyLabelTranslations(a.labelTranslations),
        svgIconBgColor: a.svgIconBgColor,
        svgIconBlack: a.svgIconBlack,
        activityList: [a.id],
      };
      if (a.id === 'OTHR') {
        activity.activityList.push(
          ...activities.filter((a2 : Activity) => !a2.inSurvey)
            .map((a2 : Activity) => a2.id),
        );
      }
      return activity;
    });

  // assign some ids
  for (let i = 0; i < aggregatedActivities.length; i += 1) {
    aggregatedActivities[i].id = i;
  }

  // sort by order
  aggregatedActivities.sort((a, b) => a.order - b.order);

  return aggregatedActivities;
}

/** A aggregate with a few main modes */
export function getMainAggregateModes(modes: Array<Mode>) : Array<AggregatedMode> {
  const car = getMode('CAR_', modes);
  const walk = getMode('WALK', modes);
  const bike = getMode('BIKE', modes);
  const exercice = getMode('EXRC', modes);
  const air = getMode('AIR_', modes);
  const other = getMode('OTHR', modes);

  /* eslint quote-props: "off" */
  const aggregatedModes = [
    {
      id: 0,
      order: 0,
      labelTranslations: walk !== null
        ? copyLabelTranslations(walk.labelTranslations)
        : { 'en': 'Walk' },
      bgColor: palette[3], // walk green
      textColor: palette['3a'],
      borderColor: palette['3c'],
      svgIconBgColor: '',
      svgIconBlack: '',
      modeList: ['WALK'],
    },
    {
      id: 1,
      order: 1,
      labelTranslations: exercice !== null
        ? copyLabelTranslations(exercice.labelTranslations)
        : { 'en': 'Exercise and recreation' },
      bgColor: exercice !== null ? exercice.bgColor : palette[4],
      textColor: exercice !== null ? exercice.textColor : palette['4a'],
      borderColor: exercice !== null ? exercice.borderColor : palette['4c'],
      svgIconBgColor: '',
      svgIconBlack: '',
      modeList: ['EXRC'],
    },
    {
      id: 2,
      order: 2,
      labelTranslations: bike !== null
        ? copyLabelTranslations(bike.labelTranslations)
        : { 'en': 'Bicycle' },
      bgColor: palette[5], // yellow-orange
      textColor: palette['5a'],
      borderColor: palette['5c'],
      svgIconBgColor: '',
      svgIconBlack: '',
      modeList: ['BIKE', 'EBKE', 'CBKE', 'SBIK'],
    },
    {
      id: 3,
      order: 3,
      labelTranslations: getTranslations('aggregated-mode.public-transport'),
      bgColor: palette[1], // blue (same as metro/tram)
      textColor: palette['1a'],
      borderColor: palette['1c'],
      svgIconBgColor: '',
      svgIconBlack: '',
      modeList: ['BUS_', 'METR', 'TRAM', 'TRN_', 'CTRN', 'CABE', 'CBUS'],
    },
    {
      id: 4,
      order: 4,
      labelTranslations: car !== null
        ? copyLabelTranslations(car.labelTranslations)
        : { 'en': 'Car' },
      bgColor: palette[4], // magenta
      textColor: palette['4a'],
      borderColor: palette['4c'],
      svgIconBgColor: '',
      svgIconBlack: '',
      modeList: [
        'CAR_', 'ECAR', 'NCAR', 'PCAR', 'BCAR', 'SCAR', 'CARD', 'CARP', 'ECRD', 'ECRP', 'NCRD', 'NCRP', 'RCAR',
      ],
    },
    {
      id: 5,
      order: 5,
      labelTranslations: air !== null
        ? copyLabelTranslations(air.labelTranslations)
        : { 'en': 'Airplane' },
      bgColor: palette['2b'],
      textColor: palette['2c'],
      borderColor: palette['2a'],
      svgIconBgColor: '',
      svgIconBlack: '',
      modeList: ['AIR_'],
    },
    // This need to be last in order to have all remaining modes added to modeList
    {
      id: 6,
      order: 6,
      labelTranslations: other !== null
        ? copyLabelTranslations(other.labelTranslations)
        : { 'en': 'Other' },
      bgColor: palette[2], // purple
      textColor: palette['2a'],
      borderColor: palette['2c'],
      svgIconBgColor: '',
      svgIconBlack: '',
      modeList: [],
    },
  ];
  // Add all unused modes to Other aggregated mode
  let usedModes : Array<string> = [];
  aggregatedModes.forEach((am : AggregatedMode) => {
    usedModes = usedModes.concat(am.modeList);
  });
  modes.forEach((m : Mode) => {
    if (usedModes.indexOf(m.id) === -1) {
      aggregatedModes[aggregatedModes.length - 1].modeList.push(m.id);
    }
  });

  return aggregatedModes;
}

/** A aggregate with a few main activities */
export function getMainAggregateActivities(activities: Array<Activity>) : Array<AggregatedActivity> {
  const work = getActivity('WORK', activities);
  const shop = getActivity('SHOP', activities);
  const edu = getActivity('EDU_', activities);
  const home = getActivity('HOME', activities);
  const other = getActivity('OTHR', activities);

  /* eslint quote-props: "off" */
  const aggregatedActivities = [
    {
      id: 0,
      order: 0,
      labelTranslations: work !== null
        ? copyLabelTranslations(work.labelTranslations)
        : { 'en': 'Work' },
      svgIconBgColor: '',
      svgIconBlack: '',
      activityList: ['WORK', 'BSTR'],
    },
    {
      id: 1,
      order: 1,
      labelTranslations: edu !== null
        ? copyLabelTranslations(edu.labelTranslations)
        : { 'en': 'Education' },
      svgIconBgColor: '',
      svgIconBlack: '',
      activityList: ['EDU_'],
    },
    {
      id: 2,
      order: 2,
      labelTranslations: shop !== null
        ? copyLabelTranslations(shop.labelTranslations)
        : { 'en': 'Shopping' },
      svgIconBgColor: '',
      svgIconBlack: '',
      activityList: ['SHOP', 'SHOG', 'SHOO'],
    },
    {
      id: 3,
      order: 3,
      labelTranslations: getTranslations('aggregated-activity.service'),
      svgIconBgColor: '',
      svgIconBlack: '',
      activityList: ['OSRV', 'HEAL', 'CHIL', 'PICC', 'PICO', 'PICG', 'PICD', 'RECY'],
    },
    {
      id: 4,
      order: 4,
      labelTranslations: getTranslations('aggregated-activity.leisure'),
      svgIconBgColor: '',
      svgIconBlack: '',
      activityList: ['ENTE', 'HOBY', 'REST', 'RUN_', 'VIST'],
    },
    {
      id: 5,
      order: 5,
      labelTranslations: home !== null
        ? copyLabelTranslations(home.labelTranslations)
        : { 'en': 'Home' },
      svgIconBgColor: '',
      svgIconBlack: '',
      activityList: ['HOME', 'SLEP'],
    },
    // This need to be last in order to have all remaining activities added to modeList
    {
      id: 6,
      order: 6,
      labelTranslations: other !== null
        ? copyLabelTranslations(other.labelTranslations)
        : { 'en': 'Other' },
      svgIconBgColor: '',
      svgIconBlack: '',
      activityList: [],
    },
  ];
  // Add all unused modes to Other aggregated activity
  let usedActivities : Array<string> = [];
  aggregatedActivities.forEach((aa : AggregatedActivity) => {
    usedActivities = usedActivities.concat(aa.activityList);
  });
  activities.forEach((a : Activity) => {
    if (usedActivities.indexOf(a.id) === -1) {
      aggregatedActivities[aggregatedActivities.length - 1].activityList.push(a.id);
    }
  });

  return aggregatedActivities;
}

/**
 * Return list of aggregated mode ids that represent the given list of modes.
 *
 * @param aggregatedModes aggregated modes config
 * @param searchList array of mode ids to search for
 * @param requireFullMatch If true, the found aggregated modes must only
 *   contain modes in searchList. If there are an aggregated mode which mixed
 *   content, this method will fail and return an empty list.
 */
export function getAggregatesForModes(
  aggregatedModes: Array<AggregatedMode>,
  searchList: Array<string>,
  requireFullMatch: boolean,
) : Array<number> {
  const result: Array<number> = [];
  let fullMatch = true;
  aggregatedModes.forEach((am) => {
    const nActive = am.modeList.reduce<number>(
      (n, curr) => n + (searchList.includes(curr) ? 1 : 0),
      0,
    );
    if (requireFullMatch
      ? nActive === am.modeList.length
      : nActive > 0) {
      result.push(am.id);
    } else if (nActive !== 0) {
      fullMatch = false;
    }
  });
  if (requireFullMatch && !fullMatch) {
    return [];
  }
  return result;
}

/**
 * Return list of aggregated mode ids that represent active modes of transport.
 *
 * If it is the case that there are aggregarted modes that contain a mix of active
 * and non-active modes of transport, then this method will fail and return an empty list.
 */
export function getActiveTransportAggregatedModes(aggregatedModes: Array<AggregatedMode>) : Array<number> {
  const activeModes = ['WALK', 'EXRC', 'BIKE', 'EBKE', 'CBKE', 'SBIK'];
  return getAggregatesForModes(aggregatedModes, activeModes, true);
}

/**
 * Return list of aggregated mode ids that consist of modes that emit emissions
 */
export function getEmissionAggregatedModes(aggregatedModes: Array<AggregatedMode>) : Array<number> {
  const emisisonModes = ['CAR_', 'BUS_', 'AIR_'];
  return getAggregatesForModes(aggregatedModes, emisisonModes, false);
}
