/**
 * Output date string in format 'YYYY-MM-DD' from a Date object
 * @param dateObj JavaScript Date object
 */
export function date2YMDString(dateObj : Date) : string {
  const z = (val : number) => {
    let s = val.toString();
    if (s.length === 1) s = `0${s}`;
    return s;
  };
  if (dateObj == null) return '';
  return `${dateObj.getFullYear()}-${z(dateObj.getMonth() + 1)}-${z(dateObj.getDate())}`; // Months are zero-based in JS
}

/**
 * Convert date string in format 'YYYY-MM-DD' to a Date object
 * @param dateStr string on format 'YYYY-MM-DD'.
 * @return Date object
 */
export function ymdString2date(dateStr : string) : Date|null {
  return new Date(dateStr);
}

/**
 * Formats given YYYY-MM-DD date string to local date string if
 * date is non-null. If date is null, then nullString is returned
 * instead.
 * @param dateStr date on YYYY-MM-DD format or null
 * @param nullString localized text to display if dateStr is null
 * @param locale this.$i18n.locale
 */
export function formatYmdString(dateStr : string|null, nullString : string, locale : string) {
  const dateFormatter = new Intl.DateTimeFormat(locale);
  if (dateStr !== null) {
    const date = ymdString2date(dateStr);
    if (date !== null) {
      return dateFormatter.format(date);
    }
  }
  return nullString;
}

/**
 * Convert time in seconds since 00:00 to a date object
 * @param time seconds since 00:00
 */
export function seconds2date(time : number) : Date {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(time);
  return date;
}

/**
 * Convert a date from eg. time2date back to seconds since 00:00
 * @param dateObj Date object with hours, minutes and seconds set.
 */
export function date2seconds(dateObj : Date) : number {
  return dateObj.getHours() * 3600 + dateObj.getMinutes() * 60 + dateObj.getSeconds();
}

/**
 * Output time string in format 'HH:MM' from a Date object
 * @param dateObj JavaScript Date object
 * @return string
 */
export function date2HMString(dateObj : Date) : string {
  let h = dateObj.getHours().toString();
  let m = dateObj.getMinutes().toString();
  if (h.length < 2) h = `0${h}`;
  if (m.length < 2) m = `0${m}`;
  return `${h}:${m}`;
}

/**
 * Returns a new date object of the day after dateObj
 * @param dateObj JavaScript Date object
 * @return JavaScript Date object
 */
export function dateGetDayAfter(dateObj : Date) : Date {
  const dateCopy = new Date(dateObj);
  dateCopy.setDate(dateCopy.getDate() + 1);
  return dateCopy;
}

/**
 * Returns a new date object of the day before dateObj
 * @param dateObj JavaScript Date object
 * @return JavaScript Date object
 */
export function dateGetDayBefore(dateObj : Date) : Date {
  const dateCopy = new Date(dateObj);
  dateCopy.setDate(dateCopy.getDate() - 1);
  return dateCopy;
}

/**
 * Returns a new date object of the first day in the
 * current ISO-8601 week (mon-sun) of given date object.
 * @param dateObj JavaScript Date object
 * @return JavaScript Date object of a Monday
 */
export function dateGetFirstDayOfWeek(dateObj : Date) : Date {
  const dateCopy = new Date(dateObj);
  let i = 0;
  while (dateCopy.getDay() !== 1) {
    dateCopy.setDate(dateCopy.getDate() - 1);

    i += 1;
    if (i > 1000) break;
  }
  return dateCopy;
}

/**
 * Returns a new date object of the last day in the
 * current ISO-8601 week (mon-sun) of given date object.
 * @param dateObj JavaScript Date object
 * @return JavaScript Date object of a Sunday
 */
export function dateGetLastDayOfWeek(dateObj : Date) : Date {
  const dateCopy = new Date(dateObj);
  let i = 0;
  while (dateCopy.getDay() !== 0) {
    dateCopy.setDate(dateCopy.getDate() + 1);

    i += 1;
    if (i > 1000) break;
  }
  return dateCopy;
}

/**
 * Returns date 'YYYY-MM-DD' of the day before for given 'YYYY-MM-DD' date string
 * @param dateStr string on format 'YYYY-MM-DD'.
 * @return string|null
 */
export function stringGetDayAfter(dateStr : string) : string|null {
  const d = ymdString2date(dateStr);
  if (d !== null) {
    return date2YMDString(dateGetDayAfter(d));
  }

  return null;
}

/**
 * Returns a new date object of the day before date_obj
 * @param dateStr string on format 'YYYY-MM-DD'.
 * @return string|null
 */
export function stringGetDayBefore(dateStr : string) : string|null {
  const d = ymdString2date(dateStr);
  if (d !== null) {
    return date2YMDString(dateGetDayBefore(d));
  }

  return null;
}

/**
 * Returns 'HH:MM (YYYY-MM-DD)' string
 * @param timestamp Unix timestamp in seconds
 */
export function timestamp2timeDateStr(timestamp : number) : string {
  const dateObj = new Date(timestamp * 1000);
  return `${date2HMString(dateObj)} (${date2YMDString(dateObj)})`;
}

/**
 * Returns the current time as unix timestamp in seconds.
 */
export function currentTimestamp() : number {
  return Math.floor(((new Date()).getTime()) / 1000);
}

/**
 * Return the delta time in seconds to add to UTC time in order to get
 * what the clock was in local time according to the timezone of the browser.
 */
export function currentTzDeltaMin() : number {
  // value (in seconds) to add to UTC time to get local time
  return -(new Date()).getTimezoneOffset();
}
