import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import store from '@/store';
import Log from '@/utils/log';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/',
    name: 'menu',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Menu.vue'),
    children: [
      {
        path: 'survey',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "survey" */ '@/views/Survey.vue'),
      },
      {
        path: 'sample',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "sample" */ '@/views/Sample.vue'),
      },
      {
        path: 'weighting',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "weighting" */ '@/views/Weighting.vue'),
      },
      {
        path: 'analysis',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "analysis" */ '@/views/Analysis.vue'),
      },
      {
        path: 'user-profile',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "user-profile" */ '@/views/UserProfile.vue'),
      },
      {
        path: 'support',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "support" */ '@/views/Support.vue'),
      },
      {
        path: 'edit-sample-variables/:from',
        name: 'edit-sample-variables',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "edit-sample-variables" */ '@/views/EditSampleVariables.vue'),
      },
    ],
  },
  {
    path: '/*',
    redirect: '/',
  },
];

/* Copied from vue-router that have note eported these types at the time of writing this */
type Position = { x: number; y: number }

// Actual vue-router scrollBehaviour doesn't work. Probably because our child routes serve
// a dom node that doesn't scroll and then further down in the three we have our <main> that
// does scroll. So instead we use some custom code. The general picture when I read up on
// the issue is that the Vue scrolling have multiple problems and it is common that people
// have to roll their own code for this. :-( /Leif, 2020-04-22
let scrollPosition: any = {};

const router = new VueRouter({
  routes,
});

// Only allow non-home views if user is logged in.
router.beforeEach(async (to: Route, from: Route, next: Function) => {
  await store.dispatch('user/checkLoggedIn');
  if (store.state.user.isLoggedIn) {
    if (store.state.survey.surveyList.length === 0) {
      await store.dispatch('survey/load');
    }
    store.dispatch('restoreSelectedSurvey');
  }

  // Save scroll position of <main>
  if (from !== null) {
    const main = document.querySelector('main');
    if (main !== null) {
      scrollPosition[from.path] = { x: main.scrollLeft, y: main.scrollTop } as Position;
      Log.log('Saved position', from.path, scrollPosition[from.path]);
    }
  }

  if (['/', '/login'].includes(to.path) && store.state.user.isLoggedIn) {
    next('/survey');
  } else if (to.path !== '/login' && !store.state.user.isLoggedIn) {
    scrollPosition = {}; // throw away saved scroll positions.
    next('/login');
  } else {
    if (to.path === '/login') {
      scrollPosition = {}; // throw away saved scroll positions.
    }
    next();
  }
});

router.afterEach(async (to: Route, from: Route) => {
  // Restore saved scroll position
  Log.log('Restore Saved position', to.path, scrollPosition[to.path]);
  if ([
    '/survey', '/sample', '/weighting', '/analysis', '/support',
  ].includes(to.path) && scrollPosition[to.path] !== undefined) {
    // We need to let the new route load into DOM before we can access
    // the right <main> element.
    setTimeout(() => {
      const main = document.querySelector('main');
      if (main !== null) {
        main.scrollLeft = scrollPosition[to.path].x;
        main.scrollTop = scrollPosition[to.path].y;
      }
    }, 1);
  }
});

export default router;
