import { ActionContext } from 'vuex';
import { loadOfflineModes, loadOfflineActivities } from '@/store/offline-data/load';
import { api } from '@/api';
import palette from '@/utils/palette';
import i18n from '@/i18n';
import {
  getMainAggregateActivities,
  getMainAggregateModes,
  getNoAggregateActivities,
  getNoAggregateModes,
} from '@/utils/maAggregate';
import {
  Mode, Activity, AggregatedMode, AggregatedActivity, ModeActivityStateInterface, AggregatedModeConfig,
} from './types';
import { RootState } from '../../root-state';

type Context = ActionContext<ModeActivityStateInterface, RootState>;

function flatLabelsToMap(maData : any) : any {
  const labelTranslations : any = {};

  Object.keys(maData).forEach((key) => {
    if (key.startsWith('label')) {
      const langKey : string = key.substring(5).toLowerCase();
      labelTranslations[langKey] = maData[key];
    }
  });

  return labelTranslations;
}

const getters = {
  /**
   * Get mode by id
   * @param id Mode id
   * @return Mode or null if mode was not found
   */
  getMode: (state:ModeActivityStateInterface) => (id : string) : Mode|null => {
    const mode = state.modes.find((m : Mode) => m.id === id);
    if (mode === undefined) return null;
    return mode;
  },
  /**
   * Get activity by id
   * @param id Activity id
   * @return Activity or null if activity was not found
   */
  getActivity: (state:ModeActivityStateInterface) => (id : string) : Activity|null => {
    const activity = state.activities.find((a : Activity) => a.id === id);
    if (activity === undefined) return null;
    return activity;
  },

  /**
   * Get a map from mode id to color string using colors
   * from current aggregated modes.
   * @param
   * @returns object mapping from string to string
   */
  getModeColorMap: (state:ModeActivityStateInterface) : any => {
    const r: any = {};
    state.aggregatedModes.forEach((am: AggregatedMode) => {
      am.modeList.forEach((modeId) => {
        r[modeId] = {
          bgColor: am.bgColor,
          textColor: am.textColor,
          borderColor: am.borderColor,
        };
      });
    });
    return r;
  },

  /**
   * Get modes in current survey
   */
  /* eslint implicit-arrow-linebreak: ["off", {}}] */
  getSurveyModes: (state:ModeActivityStateInterface) => () : Array<Mode> =>
    state.modes.filter((mode : Mode) => mode.inSurvey),

  /**
   * Get activities in current survey
   */
  /* eslint implicit-arrow-linebreak: ["off", {}}] */
  getSurveyActivities: (state:ModeActivityStateInterface) => () : Array<Activity> =>
    state.activities.filter((activity : Activity) => activity.inSurvey),

  /**
   * Get aggregated modes exculding Exercice
   * @return array of aggregated modes
   */
  getAggregatedModesExcludingExercice: (state:ModeActivityStateInterface) : AggregatedModeConfig =>
    state.aggregatedModes.filter(
      (m) => m.modeList.length !== 1 || m.modeList[0] !== 'EXRC',
    ),

  aggregatedState: (state:ModeActivityStateInterface) => {
    let s = state.aggregatedModes.map((am) => {
      let amStr = `id:${am.id};`;
      amStr += `l:${JSON.stringify(am.labelTranslations)};`;
      amStr += `c:${am.bgColor},${am.textColor},${am.borderColor};`;
      amStr += `c:${am.order};`;
      amStr += `m:${am.modeList.join(',')};`;
      return amStr;
    }).join(',');
    s += state.aggregatedActivities.map((aa) => {
      let aaStr = `id:${aa.id};`;
      aaStr += `l:${JSON.stringify(aa.labelTranslations)};`;
      aaStr += `c:${aa.order};`;
      aaStr += `m:${aa.activityList.join(',')};`;
      return aaStr;
    }).join(',');
    return s;
  },
};

/**
 * (current) User state management
 */
export default {
  namespaced: true,
  state: <ModeActivityStateInterface> {
    modes: [],
    activities: [],
    aggregatedModes: [],
    aggregatedActivities: [],
  },
  mutations: {
    clear(state:ModeActivityStateInterface) {
      state.modes = [];
      state.activities = [];
      state.aggregatedModes = [];
      state.aggregatedActivities = [];
    },
    /** Private. Set mode data in store */
    setModes(state: ModeActivityStateInterface, modes: Array<Mode>) {
      state.modes = modes;
    },
    /** Private. Set activities data in store */
    setActivities(state: ModeActivityStateInterface, activities: Array<Activity>) {
      state.activities = activities;
    },
    /** Set aggregated mode data in store */
    setAggregatedModes(state: ModeActivityStateInterface, am: Array<AggregatedMode>) {
      state.aggregatedModes = am;
    },
    /** Set aggregated activity data in store */
    setAggregatedActivities(state: ModeActivityStateInterface, aa: Array<AggregatedActivity>) {
      state.aggregatedActivities = aa;
    },

    /** Creates a aggregate setup that just 1:1 mirrors available modes */
    noAggregateModes(state: ModeActivityStateInterface) {
      state.aggregatedModes = getNoAggregateModes(state.modes);
    },
    /** Creates a aggregate setup that just 1:1 mirrors available activities */
    noAggregateActivities(state: ModeActivityStateInterface) {
      state.aggregatedActivities = getNoAggregateActivities(state.activities);
    },
    /** A aggregate with a few main modes */
    mainAggregateModes(state: ModeActivityStateInterface) {
      state.aggregatedModes = getMainAggregateModes(state.modes);
    },
    /** A aggregate with a few main activities */
    mainAggregateActivities(state: ModeActivityStateInterface) {
      state.aggregatedActivities = getMainAggregateActivities(state.activities);
    },
  },
  actions: {
    /**
     * Load mode and activity data. Depends on current survey
     *
     * Rejects upon server error or no current selected survey.
     */
    load(context:Context) {
      return new Promise<void>(async (resolve, reject) => {
        // Get modes + activities from remote/offline
        let remoteModes = [];
        let remoteActivities = [];
        let remoteSurveyModes : Array<any> = [];
        let remoteSurveyActivities : Array<any> = [];
        if (process.env.VUE_APP_NO_SERVER === '1') {
          remoteModes = loadOfflineModes();
          remoteActivities = loadOfflineActivities();
          remoteSurveyModes = remoteModes;
          remoteSurveyActivities = remoteActivities;
        } else {
          const survey = context.rootGetters['survey/getSelected'];
          if (survey === null) {
            reject(); // no survey
            return;
          }
          try {
            const sm = api.getModes(survey.id, context.rootState.user.token);
            const sa = api.getActivities(survey.id, context.rootState.user.token);
            const m = api.getModes(null, context.rootState.user.token);
            const a = api.getActivities(null, context.rootState.user.token);
            await Promise.all([
              sm,
              sa,
              m,
              a,
            ]).then(([sm, sa, m, a]) => {
              remoteSurveyModes = sm.data;
              remoteSurveyActivities = sa.data;
              remoteModes = m.data;
              remoteActivities = a.data;
            });
          } catch (e) {
            reject();
            return;
          }
        }

        // Transform data to local Mode/Activity def
        const modes = remoteModes.map((m : any) : Mode => {
          const mode = {
            id: m.id,
            order: m.order,
            labelTranslations: flatLabelsToMap(m),
            bgColor: m.bgColor,
            textColor: m.textColor,
            borderColor: m.borderColor,
            svgIconBgColor: '',
            svgIconBlack: '',
            inSurvey: remoteSurveyModes.find((value : any) => value.id === m.id) !== undefined,
          };
          return mode;
        }).filter((m : Mode) => ['NSET', 'NMOV'].indexOf(m.id) === -1);
        context.commit('setModes', modes);

        const activities = remoteActivities.map((a : any) : Activity => {
          const activity = {
            id: a.id,
            order: a.order,
            labelTranslations: flatLabelsToMap(a),
            svgIconBgColor: '',
            svgIconBlack: '',
            inSurvey: remoteSurveyActivities.find((value : any) => value.id === a.id) !== undefined,
          };
          return activity;
        }).filter((a : Activity) => ['NSET', 'NACT'].indexOf(a.id) === -1);
        context.commit('setActivities', activities);

        // Create aggeragate data that does not actually aggregate
        context.commit('noAggregateModes');
        context.commit('noAggregateActivities');

        resolve();
      });
    },
  },
  getters: {
    ...getters,
  },
};
