import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { SampleVariable, SampleClass } from '@/store/modules/sample/types';
import { apiUrl } from '@/env';
import { HomeArea } from '@/store/modules/home-area/types';
import { authHeaders } from './auth_utils';

/* eslint arrow-body-style: "off" */

export default {
  async getSampleVariables(
    surveyId : number,
    token : string,
  ) : Promise<AxiosResponse<Array<SampleVariable>>> {
    const config : AxiosRequestConfig = authHeaders(token);
    config.params = new URLSearchParams({
      surveyId: surveyId.toString(),
    });
    return axios.get<Array<SampleVariable>>(`${apiUrl}/v1/sample/sample-variables`, config);
    // return axios.get<Array<SampleVariable>>('sample-api-mock-data.json', config);
  },
  async setTargetPopulation(surveyId : number, variables : Array<SampleVariable>, token : string) {
    const data = {
      surveyId,
      variables: variables.map((variable : SampleVariable) => {
        return {
          id: variable.id,
          classes: variable.classes.map((cls : SampleClass) => {
            return {
              id: cls.id,
              targetPopulation: cls.targetPopulation,
            };
          }),
        };
      }),
    };

    return axios.put(`${apiUrl}/v1/sample/set-target-population`, data, authHeaders(token));
  },
  async setWeightOn(surveyId : number, variables : Array<SampleVariable>, token : string) {
    const data = {
      surveyId,
      variables: variables.map((variable : SampleVariable) => {
        return {
          id: variable.id,
          weightOn: variable.weightOn,
        };
      }),
    };

    return axios.put(`${apiUrl}/v1/sample/set-weight-on`, data, authHeaders(token));
  },
  async createVariables(surveyId : number, variables : Array<SampleVariable>, token : string) {
    const data = {
      surveyId,
      variables,
    };
    return axios.put(`${apiUrl}/v1/sample/create-variables`, data, authHeaders(token));
  },
  async updateVariables(surveyId : number, variables : Array<SampleVariable>, token : string) {
    const data = {
      surveyId,
      variables,
    };
    return axios.put(`${apiUrl}/v1/sample/update-variables`, data, authHeaders(token));
  },
  async deleteVariables(surveyId : number, variableIds : Array<number>, token : string) {
    const data = {
      surveyId,
      variables: variableIds.map((variableId : number) => {
        return {
          id: variableId,
        };
      }),
    };
    return axios.put(`${apiUrl}/v1/sample/delete-variables`, data, authHeaders(token));
  },
  async getHomeAreas(surveyId : number, token : string) {
    const config : AxiosRequestConfig = authHeaders(token);
    config.params = new URLSearchParams({
      surveyId: surveyId.toString(),
    });
    return axios.get<Array<HomeArea>>(`${apiUrl}/v1/sample/home-areas`, config);
  },
};
