import { Axis } from '@/store/modules/query/types';
import i18n from '../i18n';

/**
 * Helper to resolveLabel that reduces the type
 * complexity of i18n.fallbackLocale.
 */
function getFallbackLocale() : string|false {
  const fallbackLocale = i18n.fallbackLocale;
  if (typeof fallbackLocale === 'string') {
    return fallbackLocale;
  } else {
    // We only use string config in our app.
    console.assert(false, 'String i18n fallback config');
    return false;
  }
}

/**
 * Resolves label text for mixed label from server.
 * The label can either have texts in one or more languages
 * or be a string starting with 'i18n:' following with a local
 * string id for i18n or be a raw text.
 * It is also possible that there exist a map with translations
 * for one or more langKeys.
 * Priority: if both label and labelTranslations is non-null, then
 *   label has higher priority.
 * @param label string 'i18n:<string key>' or plain text label or null
 * @param labelTranslations map { langKey => translated label } or null.
 */
export default function resolveLabel(label : string|null, labelTranslations: any|null) : string {
  // Use plain text label if it is non-null
  if (label !== null) {
    if (label === '') return '';

    if (label.startsWith('i18n:')) {
      return i18n.tc(label.substr('i18n:'.length));
    }

    return label;
  }

  // Else use translations map (if it is non-null)
  if (labelTranslations === null) {
    return '';
  }
  if (labelTranslations[i18n.locale] !== undefined) {
    return labelTranslations[i18n.locale];
  }
  const fallbackLocale = getFallbackLocale();
  if (fallbackLocale !== false && labelTranslations[fallbackLocale] !== undefined) {
    return labelTranslations[fallbackLocale];
  }

  // For now beyond selected locale and fallbackLocale there is
  // no hard coded prio order of languages in TravelViewer the
  // same way as in yii-server and the app.
  for (let i = 0; i < i18n.availableLocales.length; i += 1) {
    const langKey = i18n.availableLocales[i];
    if (labelTranslations[langKey] !== undefined) {
      return labelTranslations[langKey];
    }
  }

  return '';
}

/** copy labelTranslations to new JS object */
export function copyLabelTranslations(labelTranslations : any|null) : any|null {
  const r : any = {};
  for (let i = 0; i < i18n.availableLocales.length; i += 1) {
    const langKey = i18n.availableLocales[i];
    r[langKey] = labelTranslations[langKey];
  }
  return r;
}

/**
 * Translate axis (query.axisX or query.axisY)
 *
 * Not all axises have translations and in that case the
 * raw axis is returned.
 *
 * Uses current language
 * @param axis
 * @returns translated string
 */
export function axisTranslation(axis : string) {
  if (axis === '"from zone"') {
    return i18n.t('axis.from-zone');
  } else if (axis === '"to zone"') {
    return i18n.t('axis.to-zone');
  }
  return axis;
}

/**
 * Get translated label for Query.shareOutput
 * @param shareOutput Query.shareOutput value
 * @returns translated string
 */
export function shareOutputLabel(shareOutput : boolean|Axis): string {
  if (shareOutput === Axis.x) {
    return 'x';
  } else if (shareOutput === Axis.y) {
    return 'y';
  } else {
    return shareOutput === true ? i18n.tc('state.on') : i18n.tc('state.off');
  }
}
