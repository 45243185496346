import { ActionContext } from 'vuex';
import axios, { CancelTokenSource } from 'axios';
import { api } from '@/api';
import Log from '@/utils/log';
import { RootState } from '../../root-state';
import {
  Query, QueryResult,
  SentQuery, QueryStateInterface,
  ResultQueryPromise,
} from './types';

type Context = ActionContext<QueryStateInterface, RootState>;

interface AddSentQueryParams {
  query: Query;
  apiPromise : ResultQueryPromise;
  cancelTokenSource: CancelTokenSource;
}

interface SetQueryResultParams {
  sendId: number;
  result : QueryResult;
}

interface RemoveSentQueryParams {
  sendId: number;
}

/**
 * (current) User state management
 */
export default {
  namespaced: true,
  state: <QueryStateInterface> {
    sentQueries: [],
    lastSendId: -1,
  },
  mutations: {
    /**
     * Store a query as sent
     * @param state
     * @param query
     */
    addSentQuery(state : QueryStateInterface, payload: AddSentQueryParams) {
      state.lastSendId += 1;
      const data : SentQuery = {
        id: state.lastSendId,
        sentTimestamp: (new Date()).getTime() / 1000,
        // Serialize query to make sure we store a copy of filter state etc. for this query
        serializedQuery: JSON.stringify(payload.query),
        result: null,
        apiPromise: payload.apiPromise,
        cancelTokenSource: payload.cancelTokenSource,
      };

      state.sentQueries.push(data);
    },
    setQueryResult(state : QueryStateInterface, payload : SetQueryResultParams) {
      const sentQuery = state.sentQueries.find((sq : SentQuery) => sq.id === payload.sendId);
      if (sentQuery !== undefined) {
        sentQuery.result = payload.result;
        sentQuery.cancelTokenSource = null; // not possible to cancel anymore
      }
    },
    /**
     * Removes sent query info for this query
     */
    removeSentQuery(state : QueryStateInterface, payload: RemoveSentQueryParams) {
      const index = state.sentQueries.findIndex((sq : SentQuery) => sq.id === payload.sendId);
      if (index !== -1) {
        state.sentQueries.splice(index, 1);
      }
    },
    /**
     * Removes all queries. Any ongoing query is canceled.
     */
    clear(state : QueryStateInterface, params : void) {
      // Cancel ongoing queries upon clear
      state.sentQueries.forEach((sq : SentQuery) => {
        if (sq.cancelTokenSource !== null) {
          sq.cancelTokenSource.cancel();
        }
      });

      state.sentQueries = [];
      state.lastSendId = -1;
    },
  },
  actions: {
    /**
     * Sends query to server and stores the query and later on the response
     * in store.
     * This function will resolve after the query has been sent with an id
     * that can be used with action waitOnQuery(sentId) to wait for server
     * response.
     *
     * @param context
     * @param payload
     */
    sendQuery(context : Context,
      payload: { query: Query }) : Promise<number> {
      return new Promise(async (resolve, reject) => {
        const cancel = axios.CancelToken.source();
        const apiPromise = api.resultQuery(
          payload.query, context.rootState.user.token, cancel.token,
        );
        context.commit('addSentQuery', {
          query: payload.query,
          apiPromise,
          cancelTokenSource: cancel,
        });
        const sendId = context.state.lastSendId;
        resolve(sendId); // Resolve with Id

        try {
          const response = await apiPromise;
          context.commit('setQueryResult', {
            sendId,
            result: response.data,
          });
        } catch (e) {
          if (e.response !== undefined && e.response.status === 401) {
            console.log('401 in response => logout');
            context.dispatch('user/logout', {}, { root: true });
          }
          context.commit('removeSentQuery', {
            sendId,
          });
          reject();
        }
      });
    },
    /**
     * Waits until the server request triggered by sendQuery has responded and then
     * resolves with the query result.
     * Rejects on server error or invalid sendId
     * @param sendId Id received from sendQuery
     */
    waitOnQuery(context : Context,
      payload: { sendId: number }) : Promise<QueryResult> {
      return new Promise(async (resolve, reject) => {
        // --
        Log.log('waitOnQuery', payload.sendId);
        const sentQuery = context.state.sentQueries.find(
          (sq : SentQuery) => sq.id === payload.sendId,
        );
        if (sentQuery !== undefined && sentQuery.apiPromise !== null) {
          try {
            const response = await sentQuery.apiPromise;
            resolve(response.data);
          } catch (e) {
            console.log('network error');
            reject();
          }
        } else {
          console.log('sendId not found', payload.sendId);
          reject();
        }
      });
    },
  },
  getters: {
    /**
     * Get result from query. If query has not yet responded or there
     * is an error, null is returned.
     * @param sendId id of query received from calling sendQuery
     */
    getResult: (state:QueryStateInterface) => (sendId : number) : QueryResult|null => {
      const sentQuery = state.sentQueries.find((sq : SentQuery) => sq.id === sendId);

      if (sentQuery !== undefined) {
        return sentQuery.result;
      }

      return null;
    },
    /**
     * Look for an identical query that has already been sent and return its sendId.
     *
     * Queries that have responded with an error are not regarded.
     * @param query The query to look for
     * @return SendId of found sent query or null
     */
    findSameQuery: (state:QueryStateInterface) => (query : Query) : number|null => {
      const s = JSON.stringify(query);

      // console.log('query', s);
      // state.sentQueries.forEach((sq : SentQuery) => console.log('sq', sq.serializedQuery));

      const sentQuery = state.sentQueries.find((sq : SentQuery) => sq.serializedQuery === s);

      return sentQuery !== undefined ? sentQuery.id : null;
    },
  },
};
