import { AppFlavor } from '@/store/modules/survey/types';
import { Flavor, FlavorData } from './types';

/**
 * Parses viewer flavor string to Flavor enum. Returns null if no
 * match is found.
 * @param str The string to parse
 * @returns Flavor or null
 */
export function parseFlavorStr(str : string): Flavor|null {
  if (str.toLocaleLowerCase() === Flavor.travelViewer.toLocaleLowerCase()) {
    return Flavor.travelViewer;
  } else if (str.toLocaleLowerCase() === Flavor.activeViewer.toLocaleLowerCase()) {
    return Flavor.activeViewer;
  } else {
    return null;
  }
}

/**
 * Factory function that builds the flavor data for
 * a given Flavor.
 * @param flavor
 */
export function getFlavorData(flavor : Flavor): FlavorData|null {
  switch (flavor) {
    case Flavor.travelViewer:
      return {
        id: Flavor.travelViewer,
        name: 'TravelViewer',
        appFlavor: AppFlavor.travelvu,
        supportUrl: 'mailto:travelviewer-support@travelvu.app',
        appSupportUrl: 'https://support.travelvu.app',
      } as FlavorData;
    case Flavor.activeViewer:
      return {
        id: Flavor.activeViewer,
        name: 'ActiveViewer',
        appFlavor: AppFlavor.activeTravel,
        supportUrl: 'mailto:travelviewer-support@travelvu.app',
        appSupportUrl: 'https://support.travelvu.app',
      } as FlavorData;
    default:
      return null;
  }
}
