/**
 * Drop in replacement to console class
 * that only enable log if VUE_APP_ENABLE_LOG
 * is set to 1 in .env
 */
export default class Log {
  /* eslint no-console: ["off", {}}] */

  /** Check if log is enabled */
  public static isLogEnabled() : boolean {
    return process.env.VUE_APP_ENABLE_LOG === '1';
  }

  public static log(...args: any[]) : void {
    if (Log.isLogEnabled()) {
      console.log(...args);
    }
  }

  public static warn(...args: any[]) : void {
    if (Log.isLogEnabled()) {
      console.warn(...args);
    }
  }

  public static error(...args: any[]) : void {
    if (Log.isLogEnabled()) {
      console.error(...args);
    }
  }

  public static time(...args: any[]) : void {
    if (Log.isLogEnabled()) {
      console.time(...args);
    }
  }

  public static timeEnd(...args: any[]) : void {
    if (Log.isLogEnabled()) {
      console.timeEnd(...args);
    }
  }
}
