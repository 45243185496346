import { ActionContext } from 'vuex';
import { api } from '@/api';
import { RootState } from '../../root-state';
import {
  Question, AnswerOption,
  Questionnaire, QuestionnaireStateInterface,
} from './types';

type Context = ActionContext<QuestionnaireStateInterface, RootState>;

/**
 * For fetching questionnaires from server
 *
 * @note The data model here is an aggregated one where language-variants
 * are merged into one question/answerOption via keys.
 */
export default {
  namespaced: true,
  state: <QuestionnaireStateInterface> {
    questionnaires: [],
    loaded: false,
  },
  mutations: {
    setQuestionnaires(state : QuestionnaireStateInterface, questionnaires : Array<Questionnaire>) {
      state.questionnaires = questionnaires;
      state.loaded = true;
    },
    /**
     * Remove all questionnaires.
     */
    clear(state : QuestionnaireStateInterface, params : void) {
      state.questionnaires = [];
      state.loaded = false;
    },
  },
  actions: {
    load(context:ActionContext<QuestionnaireStateInterface, RootState>) {
      return new Promise<void>(async (resolve, reject) => {
        context.commit('clear');

        const survey = context.rootGetters['survey/getSelected'];
        if (survey !== null) {
          try {
            const response = await api.getQuestionnaires(survey.id, context.rootState.user.token);
            const s2 = context.rootGetters['survey/getSelected'];
            if (s2 === null || s2.id !== survey.id) {
              reject();
              return;
            }

            // Loaded
            context.commit('setQuestionnaires', response.data);
            resolve();
          } catch (e) {
            console.log('Failed to load questionnaires');
            reject();
          }
        } else {
          console.log('Cannot load questionnaires when no survey is selected');
          reject();
        }
      });
    },
  },
  getters: {
  },
};
