/* eslint global-require: "off" */

/**
 * The purpose of this file is to provide
 * methods for requiring offline files
 * that can be called without disabling
 * eslint global-require in a whole
 * store file.
 */

export function loadOfflineModes() {
  return require('./modes.json');
}
export function loadOfflineActivities() {
  return require('./activities.json');
}
export function loadOfflineSampleVariables() {
  return require('./sample-variables.json');
}
