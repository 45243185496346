import { AxiosResponse, CancelTokenSource } from 'axios';
import { FilterStateInterface } from '../filter/types';
import { AggregatedModeConfig, AggregatedActivityConfig } from '../mode-activity/types';

export enum TravelType {
  reselement = 'reselement',
  delresa = 'delresa',
  huvudresa = 'huvudresa',
}

export enum Numerator {
  nCorrectedDay = 'nCorrectedDay',
  nHuvudresa = 'nHuvudresa',
  nHuvudresaCar = 'nHuvudresaCar',
  nHuvudresaBike = 'nHuvudresaBike',
  nDelresa = 'nDelresa',
  nReselement = 'nReselement',
  delresaDistanceM = 'delresaDistanceM',
  delresaDurationS = 'delresaDurationS',
  reselementDistanceM = 'reselementDistanceM',
  reselementDurationS = 'reselementDurationS',
  reselementCo2Kg = 'reselementCo2Kg',
  nDelresaDays = 'nDelresaDays',
  nReselementDays = 'nReselementDays',
  one = 'one', // 1
  nAxisX = 'nAxisX',
}

export enum Denominator {
  anyDayDivN = 'anyDayDivN',
  travelDayDivN = 'travelDayDivN',
  n = 'n',
  nDelresaDivN = 'nDelresaDivN',
  nReselementDivN = 'nReselementDivN',
  nHuvudresaDivN = 'nHuvudresaDivN',
  one = 'one',
}

export enum StatMethod {
  estimatingMean = 'estimatingMean',
  estimatingProportion = 'estimatingProportion',
}

export enum ActivityMethod {
  endActivity = 'end-activity',
  notHomeSleep = 'not-home-sleep',
}

export enum Axis {
  x = 'x',
  y = 'y',
}

export interface AxisFilter {
  includeIds: Array<number|String|null>; // int|string|null - list of ids in returned axis to include
}

export interface Limits {
  minDistanceM: any; // object map from string to number
  maxDistanceM: any; // object map from string to number
  minDurationS: any; // object map from string to number
  maxDurationS: any; // object map from string to number
}

export interface WeightLimits {
  // mininmum number of respondents in a class to weight on corresponding variable.
  // Recommended default: 5
  minClassN : number;
  // minimum number of respondents in total in a variable to weight on it.
  // Recommended default: 50
  minVariableN : number;
  // if exceeding minVariableN, but less than warnVariableN respondents a warning
  // will be issued.
  // Recommended default: 100
  warnVariableN : number;
}

export interface Attachment {
  fileName: string;
  content: string;
}

/* Contains properties defined in a ResultBox */
export interface PartialQuery {
  axisX : string;
  axisY : string;
  axisXFilter? : AxisFilter
  axisYFilter? : AxisFilter
  statMethod: StatMethod;
  travelType: TravelType;
  numerator: Numerator;
  denominator : Denominator;
  scaleFactor : number|null; // Scale the result by this value or null to not scale
  enableWeighting : boolean;
  zoneFile : Attachment|null; // [user input]
  shareOutput : boolean|Axis; // should result be post processed so each cell report its ratio of the table total value? [0, 1]
  // If enabled, mapFilterId is calculated by the query (which is then used to fetch result as map tiles) and no
  // further results are calculated. Should be set to false for all normal calculations.
  calculateMapFilter : boolean;
}

/* Contains all query porperties joining both each ResultBox settings
 * with global filter state and limits and other misc settings
 */
export interface Query extends PartialQuery {
  surveyId: number;
  // If this is set to false, corrected days that occur on the join date of each respondent are ignored.
  includeJoinDateInSurvey: boolean;
  filter : FilterStateInterface;
  // (resonable) Limits does not remove the actual trips, but set distanceM := null or
  // durationS := null when trips do not meet the criteria.
  limits : Limits;
  minCellN : number;
  weightLimits : WeightLimits;
  aggregatedModeConfig : AggregatedModeConfig;
  aggregatedActivityConfig : AggregatedActivityConfig;

  activityMethod : ActivityMethod; // "end-activity" or "not-home-sleep"
  nRequiresTrips : boolean; // require trips (that passes filter) to include individual in n (and x)
  vtiReselementMerge : boolean;
}

export interface QueryResultCell {
  value : number; // Value in the cell - double
  n : number; // N individuals - integer

  // For future use:
  // median : number; - median value - dobule
  // confidenceInterval : Array<number>; // [lower : double, upper : double]
}
export interface QueryResultAxisItem {
  id: number|string;
  label : string|null
  labelTranslations: any|null; // Object map langKey => translated label :string
}

export interface MapFilter {
  id: string;
  centroid: Array<number>; // array of length 2
  envelope: Array<number>; // array of length 4
  // eslint-disable-next-line camelcase
  created_at: string; // datetime string
  // eslint-disable-next-line camelcase
  updated_at: string; // datetime string
}

export interface QueryResult {
  axisX : Array<QueryResultAxisItem>;
  axisY : Array<QueryResultAxisItem>;

  cells : Array<Array<QueryResultCell>>;

  mapFilter: MapFilter|null;
}
// Located here and not in API to avoid cyclinc dependency
export type ResultQueryPromise = Promise<AxiosResponse<QueryResult>>;

export interface SentQuery {
  id: number; // sendId set locally to find the query again when result is received
  sentTimestamp : number; // unix timestamp in seconds when query was sent
  serializedQuery: string; // query serialized to string
  result: null|QueryResult; // null => not received response yet
  apiPromise : ResultQueryPromise;
  cancelTokenSource: null|CancelTokenSource; // can be used to cancel the request.

  // put any id/ticket received from server here
}

export interface QueryStateInterface {
  sentQueries: Array<SentQuery>
  lastSendId : number; // id of last added query
}
