






import Vue from 'vue';
import Component from 'vue-class-component';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  components: {},
})
export default class App extends Vue {
  /* eslint class-methods-use-this: ["off"] */
  mounted() {
    // Register ChartDataLabels. Documentation of it says
    // that it is enough to import it and it will auto register,
    // but in practice, it seems we need to register it like this:
    Chart.plugins.register(ChartDataLabels);
    Chart.defaults.global.defaultFontFamily = '"Work Sans"';
  }
}
